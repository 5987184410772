// Redirector v1.0.3
let attemptedURL = document.location.href;
console.log('Here is the URL attempt: ' + attemptedURL)
if (attemptedURL.endsWith('.html')) {
  /* empty */
} else if (attemptedURL.endsWith('/')) {
  attemptedURL += 'index.html';
  console.log('if statement')
} else {
  attemptedURL += '/index.html';
  console.log('else statement')
}
const updateLink = async () => {
  const res = await fetch(
    `https://apps.usfa.fema.gov/contact/api/redirectorJSON?oldUrl=${attemptedURL}&callback=?`
  );
  if (res?.ok) {
    const data = await res.json();

    if (Object.keys(data).length > 0) {
      document.getElementById('redirect').classList.remove('visually-hidden');
      oldURL.innerText = data.oldUrl;
      newURL.innerText = data.newUrl;
      newURL.setAttribute('href', data.newUrl);
    }
  }
};
updateLink();
